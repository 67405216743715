var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"softwaresummary",class:{ 'pb-1': !(_vm.nodescription || _vm.minimalMode) }},[_c('div',{staticClass:"media",class:{ 'mb-0': _vm.nodescription || _vm.minimalMode }},[_c('div',{staticClass:"media-left"},[_c('figure',{staticClass:"image is-48x48"},[(_vm.skeleton)?_c('b-skeleton',{attrs:{"width":"48","height":"48"}}):_c('IconDisplay',{attrs:{"src":_vm.software.icon}})],1)]),_c('div',{staticClass:"media-content"},[(_vm.skeleton)?_c('b-skeleton',{attrs:{"size":"is-large"}}):(_vm.software.displayname != '')?_c('p',{staticClass:"title",class:{
                    'is-4': !(_vm.nodescription || _vm.minimalMode),
                    'is-6': _vm.minimalMode
                }},[_vm._v(" "+_vm._s(_vm.software.displayName)+" ")]):_vm._e(),(_vm.skeleton)?_c('b-skeleton'):_c('p',{class:_vm.software.displayName !== ''
                        ? 'subtitle is-6'
                        : 'title is-4'},[_vm._v(" "+_vm._s(_vm.software.packageName)+" (version: "+_vm._s(_vm.software.version)+") ")])],1)]),_c('div',{staticClass:"is-flex flex-direction-row is-justify-content-space-between"},[(_vm.showToggleButton)?_c('SoftwareAddButton',{attrs:{"package":_vm.software.packageName}}):_vm._e(),(
                !_vm.skeleton && _vm.showToggleButton && _vm.packageList && _vm.replacement
            )?_c('div',{},[_c('a',{on:{"click":_vm.swap}},[_c('b-icon',{attrs:{"icon":"swap-horizontal-circle-outline","size":"is-small"}}),_vm._v(" Swap for "+_vm._s(_vm.replacement.displayName)+" ")],1)]):_vm._e()],1),(!(_vm.nodescription || _vm.minimalMode))?_c('div',{staticClass:"content"},[(_vm.skeleton)?_c('b-skeleton',{attrs:{"count":3}}):(_vm.software.summary !== '')?_c('p',[_vm._v(" "+_vm._s(_vm.software.summary)+" ")]):_vm._e()],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('Loader'),_c('a',{staticClass:"darkModeToggle",on:{"click":function($event){_vm.currentTheme = _vm.currentTheme === 'light' ? 'dark' : 'light'}}},[_c('b-icon',{staticStyle:{"color":"var(--background) !important"},attrs:{"icon":_vm.currentTheme === 'light'
                    ? 'white-balance-sunny'
                    : 'weather-night'}})],1),_c('InstallSideBar'),_c('b-modal',{model:{value:(_vm.packageModalVisible),callback:function ($$v) {_vm.packageModalVisible=$$v},expression:"packageModalVisible"}},[_c('SoftwareInfo',{attrs:{"software":_vm.packageModalData},on:{"toggled":function($event){return _vm.$store.commit('clearPackageModal')},"tagSelected":function (tag) {
                    _vm.currentTagFilter = [tag];
                    _vm.$store.commit('clearPackageModal');
                }}})],1),_c('section',{staticClass:"hero has-text-centered is-link"},[_c('div',{staticClass:"hero-body"},[_c('p',{staticClass:"title"},[_c('b-icon',{attrs:{"icon":"coffee","size":"is-medium"}}),_vm._v(" InstantChocolate ")],1),(_vm.timestamp)?_c('p',{staticClass:"subtitle"},[_vm._v(" Repo definition update: "+_vm._s(Intl.DateTimeFormat("en").format( new Date(_vm.timestamp * 1000) ))+" ")]):_vm._e()])]),_c('section',{staticClass:"section"},[_c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-10",attrs:{"id":"mainContent"}},[(Object.keys(_vm.packageList).length > 0)?_c('div',[(
                                _vm.currentTagFilter.length === 0 &&
                                    !_vm.searchActive
                            )?_c('Presets'):_vm._e(),(
                                _vm.currentTagFilter.length > 0 || _vm.searchActive
                            )?_c('div',{staticClass:"mb-3 is-flex is-flex-direction-row is-flex-wrap",staticStyle:{"gap":"3px"}},[_c('b-taglist',[(_vm.searchActive)?_c('b-tag',{attrs:{"type":"is-chocolate","closable":"","aria-close-label":"Close tag","size":"is-medium"},on:{"close":function($event){_vm.searchActive = false}}},[_vm._v(" Search: "+_vm._s(_vm.searchQuery)+" ")]):_vm._e(),_vm._l((_vm.currentTagFilter),function(tag){return _c('b-tag',{key:tag,attrs:{"type":"is-chocolate","closable":"","aria-close-label":"Close tag","size":"is-medium"},on:{"close":function($event){_vm.currentTagFilter.splice(
                                            _vm.currentTagFilter.indexOf(tag),
                                            1
                                        )}}},[_vm._v(" "+_vm._s(tag)+" ")])})],2)],1):_vm._e(),_c('PackageList',{attrs:{"displayList":_vm.packageListDisplay,"viewType":_vm.selectedViewType}})],1):_c('div',[_c('div',{staticClass:"grid"},_vm._l((Array(40).keys()),function(i){return _c('SoftwareItem',{key:i,attrs:{"skeleton":true}})}),1)])]),_c('div',{staticClass:"column",attrs:{"id":"sidePanel"}},[_c('b-field',{staticClass:"mb-1",attrs:{"label":"View mode"}},[_c('a',{on:{"click":function($event){_vm.selectedViewType = 'compact'}}},[_c('b-icon',{attrs:{"size":"is-medium","icon":"view-list","type":_vm.selectedViewType === 'compact'
                                        ? 'is-info'
                                        : ''}})],1),_c('a',{on:{"click":function($event){_vm.selectedViewType = 'grid'}}},[_c('b-icon',{attrs:{"size":"is-medium","icon":"view-grid","type":_vm.selectedViewType === 'grid'
                                        ? 'is-info'
                                        : ''}})],1)]),_c('SoftwareSearch',{on:{"searchComplete":function (data) {
                                _vm.searchActive = true;
                                _vm.searchResults = data.results;
                                _vm.searchQuery = data.query;
                            },"searchCleared":function($event){_vm.searchActive = false}}}),_c('TagList',{attrs:{"tags":_vm.tags,"currentTagFilter":_vm.currentTagFilter},on:{"tagSelected":function (tag) {
                                _vm.currentTagFilter.includes(tag)
                                    ? _vm.currentTagFilter.splice(
                                          _vm.currentTagFilter.indexOf(tag),
                                          1
                                      )
                                    : _vm.currentTagFilter.push(tag);
                            }}})],1)])])]),_c('footer',{staticClass:"footer"},[_c('div',{staticClass:"content has-text-centered"},[_c('b',[_vm._v("InstantChocolate")]),_vm._v(" by OctoNezd"),_c('br'),_vm._v(" This site is not made or endorsed by Chocolatey developers."),_c('br'),_vm._v(" Version: "+_vm._s(_vm.$gitcommit)+" "),_c('br'),_c('div',{staticClass:" is-flex is-align-items-center is-justify-content-center",staticStyle:{"min-height":"3em"}},[_c('b-icon',{attrs:{"icon":"github"}}),_vm._m(0)],1)])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ul',{staticClass:"github"},[_c('li'),_c('li',[_c('a',{attrs:{"href":"https://github.com/octonezd/instantchocolate","target":"_blank"}},[_vm._v("website")])]),_c('li',[_c('a',{attrs:{"href":"https://github.com/octonezd/instantchocolate.apigen","target":"_blank"}},[_vm._v("chocolatey scraper")])]),_c('li',[_c('a',{attrs:{"href":"https://github.com/octonezd/instantchocolate.installer","target":"_blank"}},[_vm._v("installer")])])])}]

export { render, staticRenderFns }